import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Typography,
  Spin,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { EditOutlined, CloseCircleOutlined } from "@ant-design/icons";
import {
  getAllTShirtSizes,
  getTShirtSizingForExpansionCluster,
  getTShirtSizingForNewCluster,
  updateProcurementStageItem,
} from "apis";
import { PROCUREMENT_STATUS, PROCUREMENT_TYPES } from "types";
import useMasterDataStore from "store/master_data_store";
import SelectClusterInfo from "./SelectClusterInfo";
import _ from "lodash";
import {
  getClusterInfo,
  initializeProcurementApi,
  updateSizingInfoAPI,
} from "apis";
import ClusterInfo from "components/forms/NewProcurementForm/ClusterInfo";

const { Text } = Typography;

const initial_data = {
  est_memory: 0,
  est_vcpus: 0,
  est_diskspace: 0,
  est_nics: 0,
};
function ProcurementIntakeStage({
  demandIntakeData,
  isUpdate,
  handlePageUpdate,
  procurementType,
  getTshirtSizeForClusterFromIntake,
  isSizingPage = false,
  setIsClusterSizeDisabled,
  handleSizingUpdate,
}) {
  const [demandIntakeDataObject, setDemandIntakeDataObject] =
    useState(demandIntakeData);
  const [existingData, setExistingData] = useState(initial_data);
  const [projection_year_1, setProjection_year_1] = useState(initial_data);
  const [projection_year_2, setProjection_year_2] = useState(initial_data);
  const [projection_year_3, setProjection_year_3] = useState(initial_data);
  const [subtotal, setSubtotal] = useState({});
  const [buffer, setBuffer] = useState(initial_data);
  const [grand_total, setGrand_total] = useState(initial_data);
  const [editBuffer, setEditBuffer] = useState(false);
  const [growthValue, setgrowthValue] = useState(5);
  const [existingClusterInfo, setExistingClusterInfo] = useState(null);
  const [showClusterInfoSection, setShowClusterInfoSection] = useState(true);
  const [selectedClusterName, setClusterName] = useState("");
  const [disableForm, setDisableForm] = useState(false);
  const [tShirtSize, setTShirtSize] = useState("");
  const [estimatedPrice, setEstimatedPrice] = useState(0);
  const [expClusterInfo, setExpClusterInfo] = useState(null);
  const [showExistingClusterInfo, setShowExistingClusterInfo] = useState(true);
  const [clusterSizesForExpProcurement, setClusterSizesForExpProcurement] =
    useState([]);
  const [estimatedTshirtSizeForSizing, setEstimatedTshirtSizeForSizing] =
    useState("");
  const [showSpin, setShowSpin] = useState(false);
  const [isIntakeStageReadOnly, setIsIntakeStageReadOnly] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [isApproveDisabled, setIsApproveDisabled] = useState(false);
  const [resources, setResources] = useState({
    cpu_cores: 0,
    memory_: 0,
    storage_tb: 0,
  });
  const [bufferRateVCpus, setBufferRateVCpus] = useState(0.6);

  const { allClusterNames } = useMasterDataStore();
  const cluster_name_options = useMemo(
    () => allClusterNames.map((item) => item.ClusterName),
    [allClusterNames]
  );
  const [isVcpuDisabled, setIsVcpuDisabled] = useState(true);

  useEffect(() => {
    if (demandIntakeData) {
      settingInitialValues(demandIntakeData);
      getTshirtSizesData(PROCUREMENT_TYPES.EXPANSION);
    }
  }, [demandIntakeData]);

  useEffect(() => {
    if (isSizingPage) {
      setEstimatedTshirtSizeForSizing(null);
      setEstimatedPrice(0);
      setShowClusterInfoSection(false);
      setExistingClusterInfo(null);
      getTshirtSizesData(PROCUREMENT_TYPES.EXPANSION);
      setResources({
        cpu_cores: 0,
        memory_: 0,
        storage_tb: 0,
      });
    }
  }, [procurementType]);

  useEffect(() => {
    if (growthValue !== 1 && bufferRateVCpus !== 1) {
      if (procurementType === PROCUREMENT_TYPES.NEW) {
        handleGrowthRateChangeUpdate(
          existingData.est_vcpus,
          "est_vcpus",
          growthValue
        );
      } else {
        handleAdditionaRequirements(existingData.est_vcpus, "est_vcpus");
      }
    }
  }, [bufferRateVCpus]);

  const checkIfObjectIsEmpty = (object) => {
    if (object !== undefined && Object.keys(object).length === 0) {
      object = { ...initial_data };
    }
    return object;
  };

  const settingInitialValues = (demandIntakeObject) => {
    // setExistingData(demandIntakeData?.existing);
    // setProjection_year_1(demandIntakeData?.projection_year_1);
    setExistingData(checkIfObjectIsEmpty(demandIntakeData?.existing));
    setProjection_year_1(
      checkIfObjectIsEmpty(demandIntakeData?.projection_year_1)
    );
    //setProjection_year_2(demandIntakeData?.projection_year_2);
    setProjection_year_2(
      checkIfObjectIsEmpty(demandIntakeData?.projection_year_2)
    );
    //setProjection_year_3(demandIntakeData?.projection_year_3);
    setProjection_year_3(
      checkIfObjectIsEmpty(demandIntakeData?.projection_year_3)
    );
    setSubtotal(demandIntakeData?.subtotal);
    setBuffer(demandIntakeData?.buffer);
    setgrowthValue(
      parseInt(
        demandIntakeData?.expected_growth_rate
          ? demandIntakeData?.expected_growth_rate
          : "5",
        10
      )
    );
    setDemandIntakeDataObject(demandIntakeData);
    setGrand_total(demandIntakeData?.grand_total);
    setExistingClusterInfo(demandIntakeData["cluster_info"]); //
    setExpClusterInfo(demandIntakeData["cluster_info"]);
    setClusterName(demandIntakeData["cluster_name"]);
    if (
      demandIntakeData["stage_status"] === PROCUREMENT_STATUS.Approved ||
      demandIntakeData["stage_status"] === PROCUREMENT_STATUS.Initial
    ) {
      setIsIntakeStageReadOnly(true);
    }
    setBufferRateVCpus(demandIntakeData["vcpu_buffer_rate"]);
  };

  const getTshirtSizesData = async () => {
    if (procurementType) {
      let result = await getAllTShirtSizes(procurementType);
      // console.log("TeshirtSizes", result.data);
      if (result.success) {
        setClusterSizesForExpProcurement(result.data);
      } else {
        console.log(result.error);
      }
    }
  };

  const getYear = (projection_year) => {
    const currentData = new Date();
    const year = currentData.getFullYear();
    return year + projection_year;
  };

  const handleBufferRate = (value) => {
    setBufferRateVCpus(value);
    if (value === 1) {
      setIsVcpuDisabled(false);
    } else {
      setIsVcpuDisabled(true);
    }
  };

  const data = [
    {
      key: "Existing",
      // name: "Existing",
      name:
        procurementType === PROCUREMENT_TYPES.NEW
          ? "Resources Needed"
          : "Resources Needed",
      est_memory: (
        <Input
          type="number"
          value={existingData?.est_memory ?? 0}
          onChange={(e) => {
            const value = e.target.value === "" ? 0 : Number(e.target.value);
            handleExistingInputChange(value, "est_memory");
          }}
          min="0"
          placeholder="Please enter RAM needed"
        />
      ),
      est_vcpus: (
        <Input
          type="number"
          value={existingData?.est_vcpus ?? 0}
          onChange={(e) =>
            handleExistingInputChange(
              e.target.value === "" ? 0 : Number(e.target.value),
              "est_vcpus"
            )
          }
          min="0"
          placeholder="Please enter CPUs needed"
        />
      ),
      est_diskspace: (
        <Input
          type="number"
          value={existingData?.est_diskspace ?? 0}
          onChange={(e) =>
            handleExistingInputChange(
              e.target.value === "" ? 0 : Number(e.target.value),
              "est_diskspace"
            )
          }
          min="0"
          placeholder="Please enter DISC needed"
        />
      ),
      est_nics: (
        <Input
          type="number"
          value={existingData?.est_nics}
          onChange={(e) =>
            handleNics(
              e.target.value === "" ? 0 : Number(e.target.value),
              "est_nics"
            )
          }
          min="0"
        />
      ),
    },
    {
      key: "projection_year_1",
      name: "+ Projections for " + getYear(1),
      est_memory: (
        <Input
          disabled={growthValue !== 1}
          type="number"
          value={projection_year_1?.est_memory}
          onChange={(e) =>
            handleProjectionYear1InputChange(
              e.target.value === "" ? 0 : Number(e.target.value),
              "est_memory"
            )
          }
          // min="0"
        />
      ),
      est_vcpus: (
        <Input
          disabled={growthValue !== 1}
          type="number"
          value={
            projection_year_1?.est_vcpus ? projection_year_1?.est_vcpus : 0
          }
          onChange={(e) =>
            handleProjectionYear1InputChange(
              e.target.value === "" ? 0 : Number(e.target.value),
              "est_vcpus"
            )
          }
          min="0"
        />
      ),
      est_diskspace: (
        <Input
          disabled={growthValue !== 1}
          type="number"
          value={
            projection_year_1?.est_diskspace
              ? projection_year_1?.est_diskspace
              : 0
          }
          onChange={(e) =>
            handleProjectionYear1InputChange(
              e.target.value === "" ? 0 : Number(e.target.value),
              "est_diskspace"
            )
          }
          min="0"
        />
      ),
      est_nics: <Text strong>-</Text>,
    },
    {
      key: "projection_year_2",
      name: "+ Projections for " + getYear(2),
      est_memory: (
        <Input
          disabled={growthValue !== 1}
          type="number"
          value={
            projection_year_2?.est_memory ? projection_year_2?.est_memory : 0
          }
          min="0"
          onChange={(e) =>
            handleProjectionYear2InputChange(
              e.target.value === "" ? 0 : Number(e.target.value),
              "est_memory"
            )
          }
        />
      ),
      est_vcpus: (
        <Input
          disabled={growthValue !== 1}
          type="number"
          value={
            projection_year_2?.est_vcpus ? projection_year_2?.est_vcpus : 0
          }
          min="0"
          onChange={(e) =>
            handleProjectionYear2InputChange(
              e.target.value === "" ? 0 : Number(e.target.value),
              "est_vcpus"
            )
          }
        />
      ),
      est_diskspace: (
        <Input
          disabled={growthValue !== 1}
          type="number"
          value={
            projection_year_2?.est_diskspace
              ? projection_year_2?.est_diskspace
              : 0
          }
          min="0"
          onChange={(e) =>
            handleProjectionYear2InputChange(
              e.target.value === "" ? 0 : Number(e.target.value),
              "est_diskspace"
            )
          }
        />
      ),
      est_nics: <Text strong>-</Text>,
    },
    {
      key: "projection_year_3",
      name: "+ Projections for " + getYear(3),
      est_memory: (
        <Input
          disabled={growthValue !== 1}
          type="number"
          value={
            projection_year_3?.est_memory ? projection_year_3?.est_memory : 0
          }
          onChange={(e) =>
            handleProjectionYear3InputChange(
              e.target.value === "" ? 0 : Number(e.target.value),
              "est_memory"
            )
          }
          min="0"
        />
      ),
      est_vcpus: (
        <Input
          disabled={growthValue !== 1}
          type="number"
          value={
            projection_year_3?.est_vcpus ? projection_year_3?.est_vcpus : 0
          }
          onChange={(e) =>
            handleProjectionYear3InputChange(
              e.target.value === "" ? 0 : Number(e.target.value),
              "est_vcpus"
            )
          }
          min="0"
        />
      ),
      est_diskspace: (
        <Input
          disabled={growthValue !== 1}
          type="number"
          value={
            projection_year_3?.est_diskspace
              ? projection_year_3?.est_diskspace
              : 0
          }
          onChange={(e) =>
            handleProjectionYear3InputChange(
              e.target.value === "" ? 0 : Number(e.target.value),
              "est_diskspace"
            )
          }
          min="0"
        />
      ),
      est_nics: <Text strong>-</Text>,
    },
  ];

  const dataExpansion = [
    {
      key: "Existing",
      name: "Additional Requirements",
      est_memory: (
        <Input
          type="number"
          value={existingData?.est_memory}
          onChange={(e) =>
            handleAdditionaRequirements(
              e.target.value === "" ? 0 : Number(e.target.value),
              "est_memory"
            )
          }
          min="0"
        />
      ),
      est_vcpus: (
        <Input
          type="number"
          value={existingData?.est_vcpus}
          onChange={(e) =>
            handleAdditionaRequirements(
              e.target.value === "" ? 0 : Number(e.target.value),
              "est_vcpus"
            )
          }
          min="0"
        />
      ),
      est_diskspace: (
        <Input
          type="number"
          value={existingData?.est_diskspace}
          onChange={(e) =>
            handleAdditionaRequirements(
              e.target.value === "" ? 0 : Number(e.target.value),
              "est_diskspace"
            )
          }
          min="0"
        />
      ),
      est_nics: (
        <Input
          type="number"
          value={existingData?.est_nics}
          onChange={(e) =>
            handleAdditionaRequirements(
              e.target.value === "" ? 0 : Number(e.target.value),
              "est_nics"
            )
          }
          min="0"
        />
      ),
    },
  ];

  const subTotal = {
    key: "subtotal",
    name: <Text strong>Subtotal</Text>,
    est_memory: <Text>{subtotal?.est_memory}</Text>,
    est_vcpus: <Text>{subtotal?.est_vcpus}</Text>,
    est_diskspace: <Text>{subtotal?.est_diskspace}</Text>,
    est_nics: <Text strong>-</Text>,
  };

  const bufferRow = {
    key: "buffer",
    name: (
      <Text strong>
        Buffer
        {!editBuffer ? (
          <EditOutlined onClick={() => setEditBuffer(true)}></EditOutlined>
        ) : (
          <CloseCircleOutlined onClick={() => setEditBuffer(false)} />
        )}
      </Text>
    ),
    est_memory: (
      <Col style={{ paddingTop: "20px" }}>
        <Space></Space>
        <Input
          disabled={!editBuffer}
          type="number"
          value={buffer?.est_memory ?? 0}
          onChange={(e) => {
            handleInputChangeBuffer(
              e.target.value === "" ? 0 : Number(e.target.value),
              "est_memory"
            );
          }}
        />
      </Col>
    ),
    est_vcpus: (
      <Col>
        <Space>
          <Radio.Group
            onChange={(e) => handleBufferRate(e.target.value)}
            value={bufferRateVCpus}
          >
            <Radio value={0.6}>60%</Radio>
            <Radio value={0.7}>70%</Radio>
            <Radio value={1}>Custom</Radio>
          </Radio.Group>
        </Space>
        {/* )} */}
        <Input
          disabled={isVcpuDisabled && !editBuffer}
          // disabled={!editBuffer}
          type="number"
          value={buffer?.est_vcpus ?? 0}
          onChange={(e) => {
            handleInputChangeBuffer(
              e.target.value === "" ? 0 : Number(e.target.value),
              "est_vcpus"
            );
          }}
        />
      </Col>
    ),
    est_diskspace: (
      <Col style={{ paddingTop: "20px" }}>
        <Space></Space>
        <Input
          disabled={!editBuffer}
          type="number"
          value={buffer?.est_diskspace ?? 0}
          onChange={(e) => {
            handleInputChangeBuffer(
              e.target.value === "" ? 0 : Number(e.target.value),
              "est_diskspace"
            );
          }}
        />
      </Col>
    ),
    // est_nics: (
    //   <Input
    //     type="number"
    //     min="0"
    //     value="-"
    //     style={{ width: "100%" }}
    //     disabled={true}
    //   />
    // ),
    est_nics: <Text strong>-</Text>,
  };

  const defaultBufferConfig = {
    est_vcpus: bufferRateVCpus,
    est_memory: 0.75,
    est_diskspace: 0.75,
    est_nics: 0,
  };

  const grandTotalRow = {
    key: "grand Total",
    name: (
      <Text strong underline>
        Grand Total
      </Text>
    ),
    est_memory: (
      <Text strong underline>
        {grand_total?.est_memory}
      </Text>
    ),
    est_vcpus: (
      <Text strong underline>
        {grand_total?.est_vcpus}
      </Text>
    ),
    est_diskspace: (
      <Text strong underline>
        {grand_total?.est_diskspace}
      </Text>
    ),
    // est_nics: (
    //   <Text strong underline>
    //     {grand_total?.["est_nics"]}
    //   </Text>
    // ),
    est_nics: <Text strong>-</Text>,
  };

  const columns = [
    {
      title: "",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "No. of vCPUs",
      dataIndex: "est_vcpus",
      key: "est_vcpus",
    },
    {
      title: "RAM (in GB)",
      dataIndex: "est_memory",
      key: "est_memory",
    },
    {
      title: "Storage (in GB)",
      dataIndex: "est_diskspace",
      key: "est_diskspace",
    },
    {
      title: "1Gbps 4 port NICs",
      dataIndex: "est_nics",
      key: "est_nics",
    },
  ];

  // if (procurementType === PROCUREMENT_TYPES.EXPANSION) {
  //   columns.push({
  //     title: "1Gbps 4 port NICs",
  //     dataIndex: "est_nics",
  //     key: "est_nics",
  //   });
  // }
  // grandTotalRow
  const finalData = [
    ...(procurementType === PROCUREMENT_TYPES.NEW ? data : dataExpansion),
    subTotal,
    bufferRow,
    grandTotalRow,
  ];

  const calculateGrowthRate = (growthRate) => {
    handleGrowthRateChangeUpdate(
      existingData?.est_memory,
      "est_memory",
      growthRate
    );
    handleGrowthRateChangeUpdate(
      existingData?.est_vcpus,
      "est_vcpus",
      growthRate
    );
    handleGrowthRateChangeUpdate(
      existingData?.est_diskspace,
      "est_diskspace",
      growthRate
    );
  };

  const handleGrowthRateChange = (value) => {
    setgrowthValue(value);
    if (value !== 1) {
      calculateGrowthRate(value);
    }
  };

  const handleGrowthRateChangeUpdate = (changedValue, field, growthRate) => {
    if (growthRate !== 1) {
      let buffervalue = 0;
      let previousValue = parseInt(changedValue, 10);
      let acc = previousValue;
      let p1NewValue = Math.ceil((previousValue * growthRate) / 100);
      previousValue = previousValue + p1NewValue;

      setProjection_year_1((prev) => ({
        ...prev,
        [field]: p1NewValue.toString(),
      }));
      acc = parseInt(acc, 10) + parseInt(p1NewValue, 10);

      let p2NewValue = Math.ceil((previousValue * growthRate) / 100);
      previousValue = previousValue + p2NewValue;
      setProjection_year_2((prev) => ({
        ...prev,
        [field]: p2NewValue.toString(),
      }));
      acc = parseInt(acc, 10) + parseInt(p2NewValue, 10);

      let p3NewValue = Math.ceil((previousValue * growthRate) / 100);
      previousValue = previousValue + p3NewValue;
      setProjection_year_3((prev) => ({
        ...prev,
        [field]: p3NewValue.toString(),
      }));
      acc = parseInt(acc, 10) + parseInt(p3NewValue, 10);
      setSubtotal((prev) => ({
        ...prev,
        [field]: acc,
      }));
      buffervalue = editBuffer
        ? buffer[field]
        : Math.round(acc * defaultBufferConfig[field]);
      !editBuffer &&
        setBuffer((prev) => ({
          ...prev,
          [field]: parseInt(buffervalue, 10),
        }));
      setGrand_total((prev) => ({
        ...prev,
        [field]: parseInt(buffervalue, 10) + acc,
      }));
    }
  };

  const calculateSubtotal = (changedValue, field, yearType) => {
    let subtotal = 0;
    let buffervalue = 0;
    if (yearType === "Existing") {
      subtotal =
        parseInt(changedValue, 10) +
        parseInt(projection_year_1[field], 10) +
        parseInt(projection_year_2[field], 10) +
        parseInt(projection_year_3[field], 10);
    } else if (yearType === "projection_year_1") {
      subtotal =
        parseInt(existingData[field], 10) +
        parseInt(changedValue, 10) +
        parseInt(projection_year_2[field], 10) +
        parseInt(projection_year_3[field], 10);
    } else if (yearType === "projection_year_2") {
      subtotal =
        parseInt(existingData[field], 10) +
        parseInt(projection_year_1[field], 10) +
        parseInt(changedValue, 10) +
        parseInt(projection_year_3[field], 10);
    } else if (yearType === "projection_year_3") {
      subtotal =
        parseInt(existingData[field], 10) +
        parseInt(projection_year_1[field], 10) +
        parseInt(projection_year_2[field], 10) +
        parseInt(changedValue, 10);
    }

    setSubtotal((prev) => ({
      ...prev,
      [field]: subtotal,
    }));
    buffervalue = editBuffer
      ? buffer[field]
      : Math.round(subtotal * defaultBufferConfig[field]);
    !editBuffer &&
      setBuffer((prev) => ({
        ...prev,
        [field]: parseInt(buffervalue, 10),
      })); // to avoid repeatitive setting of value in object

    setGrand_total((prev) => ({
      ...prev,
      [field]: subtotal + parseInt(buffervalue, 10),
    }));
  };

  const handleExistingInputChange = (changedValue, field) => {
    setExistingData((prev) => ({
      ...prev,
      [field]: changedValue,
    }));
    handleGrowthRateChangeUpdate(changedValue, field, growthValue);
    if (growthValue == 1) {
      calculateSubtotal(changedValue, field, "Existing");
    }
  };

  const handleNics = (changedValue, field) => {
    setExistingData((prev) => ({
      ...prev,
      [field]: changedValue,
    }));
  };

  const handleProjectionYear1InputChange = (changedValue, field) => {
    setProjection_year_1((prev) => ({
      ...prev,
      [field]: changedValue,
    }));
    if (growthValue == 1) {
      //for custom
      calculateSubtotal(changedValue, field, "projection_year_1");
    }
  };

  const handleProjectionYear2InputChange = (changedValue, field) => {
    setProjection_year_2((prev) => ({
      ...prev,
      [field]: changedValue,
    }));
    if (growthValue == 1) {
      calculateSubtotal(changedValue, field, "projection_year_2");
    }
  };

  const handleProjectionYear3InputChange = (changedValue, field) => {
    setProjection_year_3((prev) => ({
      ...prev,
      [field]: changedValue,
    }));
    if (growthValue == 1) {
      calculateSubtotal(changedValue, field, "projection_year_3");
    }
  };

  const handleInputChangeBuffer = (changedValue, field) => {
    setBuffer((prev) => ({
      ...prev,
      [field]: changedValue,
    }));

    setGrand_total((prev) => ({
      ...prev,
      [field]: parseInt(subtotal[field], 10) + parseInt(changedValue, 10),
    }));
  };

  const prepareDataForUpdate = () => {
    let currentData = demandIntakeDataObject;
    if (procurementType === PROCUREMENT_TYPES.NEW) {
      currentData = {
        ...currentData,
        existing: existingData,
        expected_growth_rate: growthValue,
        projection_year_1: projection_year_1,
        projection_year_2: projection_year_2,
        projection_year_3: projection_year_3,
        buffer: buffer,
        subtotal: subtotal,
        grand_total: grand_total,
        vcpu_buffer_rate: bufferRateVCpus,
      };
    } else {
      currentData = {
        ...currentData,
        existing: existingData,
        buffer: buffer,
        subtotal: subtotal,
        grand_total: grand_total,
        cluster_info: expClusterInfo,
        // cluster_info: null,
        cluster_name: selectedClusterName,
        vcpu_buffer_rate: bufferRateVCpus,
      };
    }
    return currentData;
  };

  const handleUpdateButton = async () => {
    setIsApproveDisabled(false);
    setIsIntakeStageReadOnly(false);
    setShowSpin(true);
    let currentData = prepareDataForUpdate();
    currentData = {
      ...currentData,
      stage_status: PROCUREMENT_STATUS.Submitted,
    };
    setDemandIntakeDataObject(currentData);
    let res = await updateProcurementStageItem(currentData);
    if (res.success) {
      notification.success({
        message: "Update successful !",
      });
      setShowSpin(false);
      //handlePageUpdate();
      if (procurementType === PROCUREMENT_TYPES.NEW) {
        await getTShirtSizingForNew(currentData);
      }
      if (procurementType === PROCUREMENT_TYPES.EXPANSION) {
        await getTShirtSizingForExpansion(currentData);
      }
    } else {
      setShowSpin(false);
      notification.error({
        message: "Update failed !",
        description: res.error?.toString(),
        duration: 0,
      });
    }
  };

  const getTShirtSizingForNew = async (currentData) => {
    let tShirtSizeResponse = await getTShirtSizingForNewCluster(
      currentData["grand_total"]["est_vcpus"],
      currentData["grand_total"]["est_memory"],
      currentData["grand_total"]["est_diskspace"],
      currentData["grand_total"]["est_nics"]
    );

    if (tShirtSizeResponse.success) {
      if (tShirtSizeResponse?.data) {
        try {
          setEstimatedPrice(tShirtSizeResponse?.data?.body?.price); //newTShirtSize(tShirtSizeResponse.data.price)
          setEstimatedTshirtSizeForSizing(
            tShirtSizeResponse?.data?.body?.Tshirt_size
          );
          setResources({
            // cpu_cores: tShirtSizeResponse?.data?.body?.cpu_cores,
            cpu_cores: tShirtSizeResponse?.data?.body?.vcpu,
            memory_: tShirtSizeResponse?.data?.body?.ram_gb,
            storage_tb: tShirtSizeResponse?.data?.body?.storage_tb,
          });

          if (
            // tShirtSizeResponse?.data?.body?.Tshirt_size === "not applicable"
            tShirtSizeResponse?.data?.body?.Tshirt_size.includes(
              "not applicable"
            )
          ) {
            setTShirtSize("");
            Modal.info({
              content:
                "Unable to find a Tshirt-Size for the given configuration. Please select one manually.",
            });
          } else {
            setTShirtSize(tShirtSizeResponse?.data?.body?.Tshirt_size);
            Modal.info({
              content:
                "Tshirt-Size for the given configuration is" +
                ` ${tShirtSizeResponse?.data?.body?.Tshirt_size}`,
            });

            getTshirtSizeForClusterFromIntake(
              tShirtSizeResponse?.data?.body?.Tshirt_size,
              tShirtSizeResponse?.data?.body?.price,
              tShirtSizeResponse?.data?.body?.vcpu,
              tShirtSizeResponse?.data?.body?.ram_gb,
              tShirtSizeResponse?.data?.body?.storage_tb
            );

            if (isSizingPage) {
              setEstimatedTshirtSizeForSizing(
                tShirtSizeResponse?.data?.body?.Tshirt_size
              );
            }
            if (!isSizingPage) {
              handleSizingUpdate(
                PROCUREMENT_STATUS.Initial,
                tShirtSizeResponse?.data?.body?.Tshirt_size
              );
            }
          }
        } catch (e) {
          console.log(e);
        }
      }
    } else {
      notification.error({
        message:
          "Failed to retrieve cluster info. Please try again or contact admin.",
      });
      if (isSizingPage) {
        setEstimatedTshirtSizeForSizing("");
      }
      Modal.info({
        content: "No matching Tshirt_size found.",
      });
      if (isSizingPage === false) {
        getTshirtSizeForClusterFromIntake("", "", 0, 0, 0);
        handleSizingUpdate(PROCUREMENT_STATUS.Initial, "");
      }
    }
  };

  const getTShirtSizingForExpansion = async (currentData) => {
    if (currentData["cluster_info"] == null) {
      Modal.info({
        content: ` Please select the Cluster Name `,
      });
      return;
    }
    let tShirtSizeResponse = await getTShirtSizingForExpansionCluster(
      currentData["cluster_info"]["tshirt_size_value"],
      currentData["grand_total"]["est_vcpus"],
      currentData["grand_total"]["est_memory"],
      currentData["grand_total"]["est_diskspace"],
      currentData["grand_total"]["est_nics"]
    );

    if (tShirtSizeResponse.success) {
      if (tShirtSizeResponse?.data) {
        try {
          setResources({
            cpu_cores: tShirtSizeResponse?.data?.vcpu,
            memory_: tShirtSizeResponse?.data?.ram_gb,
            storage_tb: tShirtSizeResponse?.data?.storage_gb,
          });
          if (
            tShirtSizeResponse?.code === 200 &&
            !tShirtSizeResponse?.data.Tshirt_size
          ) {
            setTShirtSize("");
            Modal.info({
              content:
                "Tshirt size not found in the database. Please select one manually.",
            });
            getTshirtSizeForClusterFromIntake("", "", 0, 0, 0);
            setEstimatedTshirtSizeForSizing("");
          } else {
            setTShirtSize(tShirtSizeResponse?.data?.Tshirt_size);
            Modal.info({
              content:
                "Tshirt-Size for the given configuration is" +
                ` ${tShirtSizeResponse?.data?.Tshirt_size}`,
            });
            setEstimatedTshirtSizeForSizing(
              tShirtSizeResponse?.data?.Tshirt_size
            );
            getTshirtSizeForClusterFromIntake(
              tShirtSizeResponse?.data?.Tshirt_size,
              "",
              tShirtSizeResponse?.data?.vcpu,
              tShirtSizeResponse?.data?.ram_gb,
              tShirtSizeResponse?.data?.storage_gb
            );

            if (!isSizingPage) {
              handleSizingUpdate(
                PROCUREMENT_STATUS.Initial,
                tShirtSizeResponse?.data?.Tshirt_size
              );
            }
          }
        } catch (e) {
          console.log(e);
        }
      }
    } else {
      notification.error({
        message:
          "Failed to retrieve cluster info. Please try again or contact admin.",
      });
      Modal.info({
        content: "unable to determine T-Shirt Size in existing class.",
      });
      if (isSizingPage === false) {
        getTshirtSizeForClusterFromIntake("", "", 0, 0, 0);
        handleSizingUpdate(PROCUREMENT_STATUS.Initial, "");
      }
    }
  };

  const handleAdditionaRequirements = (changedValue, field) => {
    let buffervalue = 0;
    setExistingData((prev) => ({
      ...prev,
      [field]: changedValue,
    }));
    setSubtotal((prev) => ({
      ...prev,
      [field]: changedValue,
    }));
    if (editBuffer) {
      buffervalue = buffer[field];
      setGrand_total((prev) => ({
        ...prev,
        [field]: parseInt(buffervalue, 10) + parseInt(changedValue, 10),
      }));
    } else {
      buffervalue = Math.round(changedValue * defaultBufferConfig[field]);
      setBuffer((prev) => ({
        ...prev,
        [field]: parseInt(buffervalue, 10),
      }));
      setGrand_total((prev) => ({
        ...prev,
        [field]: parseInt(buffervalue, 10) + parseInt(changedValue, 10),
      }));
    }
  };

  const onClusterInfoChange = async (changedValues) => {
    setClusterName(changedValues);
    setShowExistingClusterInfo(false);
    if (cluster_name_options.includes(changedValues)) {
      await retrieveExistingClusterInfo(changedValues);
    } else {
      setShowClusterInfoSection(false);
      setExistingClusterInfo(null);
      setClusterName("");
    }
  };

  const retrieveExistingClusterInfo = async (cluster_name) => {
    setDisableForm(true);
    setExistingClusterInfo({
      cluster_name,
      nodes: null,
    });
    setShowClusterInfoSection(true);
    let result = await getClusterInfo(
      cluster_name,
      _.get(
        _.find(allClusterNames, (item) => item.ClusterName === cluster_name),
        "merck_region",
        null
      )
    );
    setDisableForm(false);
    if (result.success) {
      if (result?.data?.nodes) {
        try {
          result.data.nodes = _.map(result.data.nodes, (value, key) => ({
            Node: key,
            ...value,
          }));
          // setExpClusterInfo({ ...result.data });
          setExpClusterInfo((prev) => ({
            ...prev,
            nodes: result.data.nodes,
            tshirt_size_value: result?.data?.tshirt_size_value.includes(
              "not applicable"
            )
              ? ""
              : result?.data?.tshirt_size_value,
          }));

          // Calculate totals but exclude nodes with names starting with 'usage_stats%'
          let total_cpu_cores = _.sumBy(result.data.nodes, (node) =>
            node.Node.startsWith("usage_stats") ? 0 : node.num_cpu_cores
          );
          let total_ram = _.sumBy(result.data.nodes, (node) =>
            node.Node.startsWith("usage_stats") ? 0 : node.memory_capacity_gb
          );
          let total_no_of_disks = _.sumBy(result.data.nodes, (node) =>
            node.Node.startsWith("usage_stats")
              ? 0
              : node.host_disks_reference_list
          );
          setExistingClusterInfo({
            ...result.data,
            node_specs_totals: {
              num_cpu_cores: total_cpu_cores,
              memory_capacity_gb: total_ram,
              host_disks_reference_list: total_no_of_disks,
              host_nics_id_list: "-",
              Node: "Total",
            },
          });
          let estimated_tshirt_size = result?.data?.tshirt_size_value;
          console.log("estimated_tshirt_size", estimated_tshirt_size);
          // if (estimated_tshirt_size === "not applicable")
          if (estimated_tshirt_size.includes("not applicable")) {
            Modal.info({
              content:
                "Unable to determine cluster best fit " +
                `${estimated_tshirt_size}`,
            });
          } else {
            Modal.info({
              content:
                "Cluster Best Fit is" + ` ${result?.data?.tshirt_size_value}`,
            });
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        setExistingClusterInfo(result.data);
      }
    } else {
      notification.error({
        message:
          "Failed to retrieve cluster info. Please try again or contact admin.",
      });
      setShowClusterInfoSection(false);
    }
  };

  const handleEstimateSizeForSizing = async () => {
    let currentData = demandIntakeDataObject;
    if (procurementType === PROCUREMENT_TYPES.NEW) {
      currentData = {
        ...currentData,
        existing: existingData,
        expected_growth_rate: growthValue,
        projection_year_1: projection_year_1,
        projection_year_2: projection_year_2,
        projection_year_3: projection_year_3,
        buffer: buffer,
        subtotal: subtotal,
        grand_total: grand_total,
      };
    } else {
      currentData = {
        ...currentData,
        existing: existingData,
        buffer: buffer,
        subtotal: subtotal,
        grand_total: grand_total,
        cluster_info: expClusterInfo,
        cluster_name: selectedClusterName,
      };
    }

    setDemandIntakeDataObject(currentData);
    console.log("currentData", currentData);
    if (procurementType === PROCUREMENT_TYPES.NEW) {
      await getTShirtSizingForNew(currentData);
    }
    if (procurementType === PROCUREMENT_TYPES.EXPANSION) {
      await getTShirtSizingForExpansion(currentData);
    }
  };

  const formatPrice = () => {
    return `$${estimatedPrice
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  };

  const handelClusterBestFit = async () => {
    await retrieveExistingClusterInfo(selectedClusterName);
  };

  const handleClusterBestFitOnchange = (selectedClusterSize) => {
    setExpClusterInfo((prev) => ({
      ...prev,
      tshirt_size_value: selectedClusterSize,
    }));
  };

  const handleApproveButton = async () => {
    let currentData = prepareDataForUpdate();
    currentData = {
      ...currentData,
      stage_status: PROCUREMENT_STATUS.Approved,
    };
    setDemandIntakeDataObject(currentData);
    let res = await updateProcurementStageItem(currentData);
    if (res.success) {
      setIsIntakeStageReadOnly(true); //setting true to make component read only
      handleSizingUpdate(PROCUREMENT_STATUS.Submitted, tShirtSize);
      setShowEdit(false);
      setIsApproveDisabled(true);
      setIsVcpuDisabled(true)
    } else {
      setShowSpin(false);
      notification.error({
        message: "Request approval failed",
        description: res.error?.toString(),
        duration: 0,
      });
    }
  };

  const handleEditButton = () => {
    setIsIntakeStageReadOnly(false);
    setShowEdit(true);
    setIsApproveDisabled(false);
    if (demandIntakeData["stage_status"] === PROCUREMENT_STATUS.Initial) {
      setShowEdit(true);
    }
  };

  const sizingData = [
    {
      key: "resources",
      name: <Text strong>Resources</Text>,
      est_memory: <Text>{resources?.memory_}</Text>,
      est_vcpus: <Text>{resources?.cpu_cores}</Text>,
      est_diskspace: <Text>{resources?.storage_tb}</Text>,
    },
  ];
  const columns1 = [
    {
      title: "",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "No. of vCPUs",
      dataIndex: "est_vcpus",
      key: "est_vcpus",
    },
    {
      title: "RAM (in GB)",
      dataIndex: "est_memory",
      key: "est_memory",
    },
    {
      title: "Storage (in GB)",
      dataIndex: "est_diskspace",
      key: "est_diskspace",
    },
  ];

  const handleClearButton = () => {
    setExistingData(initial_data);
    setProjection_year_1(initial_data);
    setProjection_year_2(initial_data);
    setProjection_year_3(initial_data);
    setSubtotal(initial_data);
    setBuffer(initial_data);
    setGrand_total(initial_data);
    setgrowthValue(5);
    setBufferRateVCpus(0.6)
  };

  const ResetButton = ()=>{
    return(
      <Form.Item>
      <Button
        variant="outlined"
        style={{
          color: "#00857c",
          border: "1px solid #00857c",
          fontSize: "15px",
          fontWeighteight: "500",
          borderRadius: "5px",
          boxShadowhadow: "0 2px 0 rgba(3, 65, 52, 0.31)",
        }}
        onClick={handleClearButton}
      >
        Reset
      </Button>
      </Form.Item>
    )
  }

  return (
    <div
      id="initial-demand-section"
      style={{
        backgroundColor: "white",
        padding: "40px",
        border: "3px solid #f6f6f6",
        borderRadius: "10px",
      }}
    >
      <Row gutter={[20, 0]} align={"middle"} justify={"center"}>
        <Col span={24}>
          <Form disabled={disableForm || isIntakeStageReadOnly}>
            {procurementType === PROCUREMENT_TYPES.EXPANSION && (
              <Row gutter={[10, 10]} disabled={isIntakeStageReadOnly}>
                <Col span={8}>
                  <Text>Cluster Name : </Text>
                  <SelectClusterInfo
                    options={cluster_name_options}
                    onClusterInfoChange={onClusterInfoChange}
                    disabled={disableForm}
                    clusterName={
                      isSizingPage ? "" : demandIntakeDataObject["cluster_name"]
                    }
                  />
                </Col>

                <Col span={5}>
                  {isSizingPage === false && (
                    <Form.Item>
                      <Button type="primary" onClick={handelClusterBestFit}>
                        Cluster Best Fit
                      </Button>
                    </Form.Item>
                  )}
                </Col>
                <Col span={11}>
                  <Text>Cluster Best fit : </Text>
                  <Select
                    virtual={false}
                    options={clusterSizesForExpProcurement.map((item) => {
                      return {
                        label: item["Tshirt_size"],
                        value: item["Tshirt_size"],
                      };
                    })}
                    style={{ width: "60%" }}
                    value={expClusterInfo?.["tshirt_size_value"]}
                    onChange={handleClusterBestFitOnchange}
                  />
                </Col>
              </Row>
            )}
            <Row gutter={[10, 10]} align={"middle"} justify={"center"}>
              {procurementType === PROCUREMENT_TYPES.NEW && (
                <Col span={24}>
                  <Space>
                    <Text strong>Growth Rate</Text>
                    <Radio.Group
                      onChange={(e) => handleGrowthRateChange(e.target.value)}
                      value={growthValue}
                    >
                      <Radio value={5}>5% SFN L2</Radio>
                      <Radio value={15}>15% SFN L3</Radio>
                      <Radio value={1}>Custom</Radio>
                    </Radio.Group>
                  </Space>
                </Col>
              )}

              {(procurementType === PROCUREMENT_TYPES.EXPANSION) &
              showClusterInfoSection &
              (existingClusterInfo != null) ? (
                <Col span={24} style={{ padding: "10px" }}>
                  <ClusterInfo
                    info={existingClusterInfo}
                    cluster_name={selectedClusterName}
                  />
                </Col>
              ) : (
                <></>
              )}
              <Col span={24}>
                <Table
                  dataSource={finalData}
                  columns={columns}
                  pagination={false}
                  bordered
                  size="small"
                  style={{ marginBottom: "20px" }}
                />
              </Col>
            </Row>
          </Form>
          {isUpdate === true ? (
            <Row justify={"space-between"}>
              <Space>
                <Form.Item>
                  {(demandIntakeData["stage_status"] ===
                    PROCUREMENT_STATUS.Approved &&
                    !showEdit) ||
                  (demandIntakeData["stage_status"] ===
                    PROCUREMENT_STATUS.Initial &&
                    !showEdit) ? (
                    <Button type="primary" onClick={handleEditButton}>
                      Edit
                    </Button>
                  ) : (
                    <Button type="primary" onClick={handleUpdateButton}>
                      Update
                    </Button>
                  )}
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={handleApproveButton}
                    disabled={isApproveDisabled}
                  >
                    Approve
                  </Button>
                </Form.Item>
              </Space> 
              <ResetButton/>
            </Row>
          ) : (
            <></>
          )}
          {isSizingPage === true ? (
            <>
            <Row justify={"space-between"}>
              <Form.Item>
                <Button type="primary" onClick={handleEstimateSizeForSizing}>
                  Estimate Cluster Size
                </Button>
              </Form.Item>
              <ResetButton/>
              </Row>
              <Row>
                <Col span={16}>
                  <Form.Item>
                    <Text>
                      {procurementType === PROCUREMENT_TYPES.NEW
                        ? "Estimated cluster size :"
                        : "Additional Nodes :"}{" "}
                    </Text>
                    <Input
                      style={{ width: "50%" }}
                      value={estimatedTshirtSizeForSizing}
                      disabled
                    />
                  </Form.Item>
                </Col>
                {procurementType === PROCUREMENT_TYPES.NEW && (
                  <Col span={8}>
                    <Form.Item>
                      <Text>Estimated Price: </Text>
                      <Input
                        style={{ width: "50%" }}
                        value={formatPrice()}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                )}
                <Col span={24}>
                  <Table
                    dataSource={sizingData}
                    columns={columns1}
                    pagination={false}
                    bordered
                    size="small"
                    style={{ marginBottom: "20px" }}
                  />
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}
          {showSpin && (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1000,
              }}
            >
              <Spin size="large" tip="updating data..." />
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default ProcurementIntakeStage;
